import React, { Component } from 'react';
import './App.css';
import Image_pav from './Image_pav';
import Level from './Level';
import Tonnage_volume from './Tonnage_volume';
import Item_anomalie from './Item_anomalie';
import btn_icon_503440 from './images/btn_icon_503440.png';
import img_elImageCopy from './images/ConteneurScreen_elImageCopy_822621.png';
import Item_tri from './Item_tri';
import img_elBig_image from './images/ConteneurScreen_elImageCopy_822621.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class ConteneurScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, dataSheetRow, ds_vidage, address, flux

  constructor(props) {
    super(props);
    
    this.state = {
      elBig_image_visible: false,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  onClick_elText = (ev) => {
    console.log(this.props);
    console.log(this.props.appActions.dataSheets.selected_column);
  
  }
  
  
  onClick_elImage = (ev) => {
    this.props.appActions.updateDataSlot('ds_state', (parseInt(this.props.appActions.dataSlots['ds_state']) + 1)%5);
    
  
  }
  
  
  onClick_elLevel = (ev) => {
    this.props.appActions.updateDataSlot('ds_state', (parseInt(this.props.appActions.dataSlots['ds_state']) + 1)%5);
  
  }
  
  
  onClick_elCaptureCopy = (ev) => {
    // Go to screen 'Appareil photo'
    this.props.appActions.goToScreen('appareilphoto', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elImageCopy = (ev) => {
    this.setState({elBig_image_visible: true});
  
  }
  
  
  onClick_elButton = (ev) => {
    window.open('https://www.neurowaste.com/#contact', '_blank');
  
  }
  
  
  onClick_elBig_image = (ev) => {
    this.setState({elBig_image_visible: false});
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const dataSheet_tri = this.props.dataSheets['tri'];
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    
    const value_text = this.props.address;
    
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const value_textCopy = this.props.flux;
    
    const style_elTextCopy = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const value_scanned_id = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_scanned_id'] : '');
    
    const style_elScanned_id = {
      fontSize: 12.2,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_elRectangleCopy2 = {
      background: 'rgba(169, 169, 169, 1.000)',
     };
    const style_elAnomaliesCopy = {
      color: '#65d0c4',
      textAlign: 'center',
     };
    const style_elTextCopy2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_elRectangleCopy3 = {
      background: 'rgba(169, 169, 169, 1.000)',
     };
    const style_elAnomaliesCopy3 = {
      color: '#65d0c4',
      textAlign: 'center',
     };
    let transformStateValue_elImage = (input) => {
      switch (input) {
        case 'VERRE': 
          return 0; 
          break;
        case 'EMBALLAGES': 
          return 1; 
          break;
        case 'OM': 
          return 2; 
          break;
        default: 
          return 0;
      }
    }
    const style_elImage = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elLevel = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elRectangle = {
      background: 'rgba(169, 169, 169, 1.000)',
     };
    const style_elAnomalies2 = {
      color: '#65d0c4',
      textAlign: 'center',
     };
    
    // Source items and any special components used for list/grid element 'list 2'.
    let items_list2 = [];
    let listComps_list2 = {};
    items_list2.push({key: 'state0', visualStateIndex: 0});
    items_list2.push({key: 'state1', visualStateIndex: 1});
    items_list2.push({key: 'state2', visualStateIndex: 2});
    
    const style_elList2 = {
      height: 'auto',  // This element is in scroll flow
     };
    const style_elCaptureCopy = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_503440+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '23.333%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elImageCopy = {
      backgroundImage: 'url('+((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_camera'] : '') || img_elImageCopy)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elRectangleCopy = {
      background: 'rgba(169, 169, 169, 1.000)',
     };
    const style_elAnomaliesCopy2 = {
      color: '#65d0c4',
      textAlign: 'center',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(this.props.appActions.getDataSheet('tri').items);
    
    const style_elList = {
      height: 'auto',  // This element is in scroll flow
     };
    const style_elButton = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elBig_image = {
      backgroundImage: 'url('+((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_camera'] : '') || img_elBig_image)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elBig_image = this.state.elBig_image_visible ? (
      <div className="elBig_image" style={style_elBig_image} onClick={this.onClick_elBig_image}  />
     ) : null;
    
    return (
      <div className="AppScreen ConteneurScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elText">
            <div className="headlineFont" style={style_elText} onClick={this.onClick_elText} >
              <div>{value_text !== undefined ? value_text : (<span className="propValueMissing">{this.props.locStrings.relevepav_text_186430}</span>)}</div>
            </div>
          </div>
          
          <div className="elTextCopy">
            <div className="baseFont" style={style_elTextCopy}>
              <div>{value_textCopy !== undefined ? value_textCopy : (<span className="propValueMissing">{this.props.locStrings.relevepav_textcopy_446278}</span>)}</div>
            </div>
          </div>
          
          <div className="elScanned_id">
            <div className="systemFontRegular" style={style_elScanned_id}>
              <div>{value_scanned_id !== undefined ? value_scanned_id : (<span className="propValueMissing">{this.props.locStrings.scanner_text_56147}</span>)}</div>
            </div>
          </div>
          
          <div className="elRectangleCopy2">
            <div style={style_elRectangleCopy2} />
          </div>
          
          <div className="elAnomaliesCopy">
            <div className="baseFont" style={style_elAnomaliesCopy}>
              <div>{this.props.locStrings.conteneur_anomaliescopy_561961}</div>
            </div>
          </div>
          
          <div className="elTextCopy2">
            <div className="baseFont" style={style_elTextCopy2}>
              <div>{this.props.locStrings.conteneur_textcopy2_869825}</div>
            </div>
          </div>
          
          <div className="elRectangleCopy3">
            <div style={style_elRectangleCopy3} />
          </div>
          
          <div className="elAnomaliesCopy3">
            <div className="baseFont" style={style_elAnomaliesCopy3}>
              <div>{this.props.locStrings.conteneur_anomaliescopy3_438397}</div>
            </div>
          </div>
          
          <div className="hasNestedComps elImage">
            <div style={style_elImage} onClick={this.onClick_elImage} >
              <Image_pav visualStateIndex={transformStateValue_elImage(this.props.dataSheetRow ? this.props.dataSheetRow.flux : '')} ref={(el)=> this._elImage = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="hasNestedComps elLevel">
            <div style={style_elLevel} onClick={this.onClick_elLevel} >
              <Level visualStateIndex={(this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_state'] : '')} ref={(el)=> this._elLevel = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="elSpacer3">
            <div />
          </div>
          
          <div className="hasNestedComps elComp">
            <div>
              <Tonnage_volume ref={(el)=> this._elComp = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="elRectangle">
            <div style={style_elRectangle} />
          </div>
          
          <div className="elAnomalies2">
            <div className="baseFont" style={style_elAnomalies2}>
              <div>{this.props.locStrings.relevepav_textcopy2_1038173}</div>
            </div>
          </div>
          
          <div className="hasNestedComps elList2">
            <ul style={style_elList2}>
              {items_list2.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list2[row._componentId]
                    : <Item_anomalie visualStateIndex={row.visualStateIndex} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._elList2_endMarker = el} />
            </ul>
          </div>
          
          <div className="elCaptureCopy">
            <button className="actionFont" style={style_elCaptureCopy} onClick={this.onClick_elCaptureCopy}  />
          </div>
          
          <div className="elImageCopy">
            <div style={style_elImageCopy} onClick={this.onClick_elImageCopy}  />
          </div>
          
          <div className="elRectangleCopy">
            <div style={style_elRectangleCopy} />
          </div>
          
          <div className="elAnomaliesCopy2">
            <div className="baseFont" style={style_elAnomaliesCopy2}>
              <div>{this.props.locStrings.conteneur_anomaliescopy2_716399}</div>
            </div>
          </div>
          
          <div className="hasNestedComps elList">
            <div style={style_elList}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <Item_tri dataSheetId={'tri'} dataSheetRow={row} {...{ 'flux': row['flux'], 'image': row['image'], }} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />;
                return (<div key={row.key}>
                    {itemComp}
                  </div>);
              })}
              <div className="marker" ref={(el)=> this._elList_endMarker = el} />
            </div>
          </div>
          
          <div className="elButton">
            <Button className="actionFont" style={style_elButton}  color="accent" onClick={this.onClick_elButton} >
              Valider
            </Button>
          </div>
          
          <div className="elSpacer2">
            <div />
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Conteneur</div>  <div className="backBtn" onClick={ (ev)=>{ this.props.appActions.goBack() } }></div>
        </Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            { elBig_image }
          </div>
        </div>
      </div>
    )
  }
  
}

import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_localizationSheet extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['key'] = "start_button_922055";
    item['fr'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "button1_title_971988";
    item['fr'] = "Part X";
    
    item = {};
    this.items.push(item);
    item['key'] = "button2_title_152132";
    item['fr'] = "Part X";
    
    item = {};
    this.items.push(item);
    item['key'] = "mainpage_button_684083";
    item['fr'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "main_text_509729";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "main_text2_801476";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "main_button_262185";
    item['fr'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "main_text3_597359";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_text_947931";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen3_button_786296";
    item['fr'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_text_909366";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_text_566393";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_textcopy_471427";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_textcopy_533421";
    item['fr'] = "_test";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_n_pleinescopy_856645";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_average_ncopy_423177";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_total_pcopy_801538";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_text_727026";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_textcopy_315093";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_text_592033";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_textcopy_629092";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_deacopy_957701";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_dea_pointcopy_781277";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_dea_pointcopy2_299680";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_dea_pointcopy3_682406";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_dea_pointcopy4_534736";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_dea_pointcopy5_612423";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_dea_pointcopy6_390271";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_dea_pointcopy7_455842";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_dea_pointcopy8_524785";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_dea_pointcopy9_624921";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_dib_pointcopy_310901";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_papier_pointcopy_97745";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_platre_pointcopy_176349";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_dea_pointcopy_87895";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_bois_pointcopy_142347";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_carton_pointcopy_998540";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_ferraille_pointcopy_118119";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_gravats_pointcopy_644252";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_gravats_sales_pointcopy_959189";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_dib_pointcopy_814699";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_papier_pointcopy_646394";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_platre_pointcopy_251423";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_vegetaux_pointcopy_768025";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_checkbox_197872";
    item['fr'] = "Pleines";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_text_227657";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_text_280084";
    item['fr'] = "DEA";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_textcopy_733261";
    item['fr'] = "DEA";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_textcopy_808232";
    item['fr'] = "BOIS";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_textcopy2_684869";
    item['fr'] = "CARTON";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_textcopy3_382968";
    item['fr'] = "FERRAILLE";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_textcopy4_328170";
    item['fr'] = "GRAVATS";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_textcopy5_866598";
    item['fr'] = "GRAVATS SALES";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_textcopy6_52945";
    item['fr'] = "DIB";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_textcopy7_902919";
    item['fr'] = "PAPIER";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_textcopy8_476748";
    item['fr'] = "PLATRE";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_textcopy9_733636";
    item['fr'] = "VEGETAUX";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_text2_247145";
    item['fr'] = "DEA";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_text_78807";
    item['fr'] = "DEA";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_site_832659";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_dea_point_991424";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_dea_pointcopy_375201";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_bois_point_70089";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_bois_pointcopy_207673";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_carton_point_981323";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_carton_pointcopy_51589";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_ferraille_point_294383";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_ferraille_pointcopy_996888";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_gravats_point_597190";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_gravats_pointcopy_456981";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_gravats_sales_point_222745";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_gravats_sales_pointcopy_836296";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_dib_point_67725";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_dib_pointcopy_189917";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_papier_point_770739";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_papier_pointcopy_399812";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_platre_point_898626";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_platre_pointcopy_70395";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_vegetaux_point_265492";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_vegetaux_pointcopy_372522";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_text_973973";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_text_985940";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_sitecopy_128567";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_text_639179";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_sup_seuilcopy_452819";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_tot_videcopy_699658";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_tot_pleincopy_196426";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_tps_ecoulecopy_608492";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_f1_nvocopy_38594";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_f1_nvocopy_363608";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_f1_futurecopy_633773";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_f1_pleincopy_633789";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_f1_nvocopy_230438";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_checkbox_746681";
    item['fr'] = "Prévision 1h";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_text_12307";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_checkboxcopy_658281";
    item['fr'] = "Pleines";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_field_32189";
    item['fr'] = "seuil";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_f1_pleincopy_772029";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_f2_pleincopy_135142";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_f3_pleincopy_75765";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_f4_pleincopy_958437";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item2_f1_nvo_319024";
    item['fr'] = "MEUBLES";
    
    item = {};
    this.items.push(item);
    item['key'] = "item2_site_26091";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_f1copy_434781";
    item['fr'] = "CARTON";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_f1copy2_257775";
    item['fr'] = "BOIS";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_f1copy3_32941";
    item['fr'] = "FERRAILLE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_f1copy4_987910";
    item['fr'] = "GRAVATS";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_f1copy5_394126";
    item['fr'] = "GRAVATS NV";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_f1copy6_812628";
    item['fr'] = "DIB";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_f1copy7_447582";
    item['fr'] = "PAPIER";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_f1copy8_541154";
    item['fr'] = "PLATRE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_f1copy9_900294";
    item['fr'] = "VEGETAUX";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_text_529563";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_text_953776";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_f1copy9_526532";
    item['fr'] = "VEGETAUX";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_f1copy8_298608";
    item['fr'] = "PLATRE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_f1copy7_886869";
    item['fr'] = "PAPIER";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_f1copy6_1036145";
    item['fr'] = "DIB";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_f1copy5_732587";
    item['fr'] = "GRAVATS NV";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_f1copy4_403083";
    item['fr'] = "GRAVATS";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_f1copy3_515312";
    item['fr'] = "FERRAILLE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_f1copy2_1020659";
    item['fr'] = "BOIS";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_f1copy_397751";
    item['fr'] = "CARTON";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_f1_640902";
    item['fr'] = "MEUBLES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_text_311415";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_text_171742";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item2_tot_videcopy_142636";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item2_sup_seuilcopy_357161";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_text_296897";
    item['fr'] = "BOIS";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy_11386";
    item['fr'] = "MEUBLES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy_1028163";
    item['fr'] = "CARTON";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy2_676170";
    item['fr'] = "CARTON";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy_642565";
    item['fr'] = "CARTON";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy2_616126";
    item['fr'] = "FERRAILLE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy3_501952";
    item['fr'] = "GRAV";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy4_877196";
    item['fr'] = "GRAV NV";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy5_279603";
    item['fr'] = "PAPIER";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy6_147729";
    item['fr'] = "PLATRE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy7_380240";
    item['fr'] = "DIB";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy8_23991";
    item['fr'] = "VEGET";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy9_105431";
    item['fr'] = "PRES DIB";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_f1copy_589090";
    item['fr'] = "GRAVATS NV";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f6_plein_408475";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f6_nvo_259108";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f6_future_498501";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f10_plein_32046";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f10_nvo_259640";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f10_future_208634";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f9_plein_336354";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f9_future_309244";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f9_nvo_755165";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f8_plein_292171";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f8_nvo_601916";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f8_future_410552";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f7_plein_479867";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f7_future_42180";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f7_nvo_726443";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_f10_nvocopy_933086";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_f10_nvocopy2_171734";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy10_751048";
    item['fr'] = "m3";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy11_40942";
    item['fr'] = "30 m3";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy12_654787";
    item['fr'] = "VIDES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy13_204406";
    item['fr'] = "MATIERES";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_text2_336463";
    item['fr'] = "Pilotage du parc";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_textcopy_306997";
    item['fr'] = "Pilotage du parc";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_textcopy2_461497";
    item['fr'] = "Connecté en tant que";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_text3_707603";
    item['fr'] = "Seuil critique";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_textcopy3_964119";
    item['fr'] = "%";
    
    item = {};
    this.items.push(item);
    item['key'] = "outildepilotage_text_747430";
    item['fr'] = "Bienvenue";
    
    item = {};
    this.items.push(item);
    item['key'] = "outildepilotage_textcopy_665386";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "outildepilotage_textcopy2_157778";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "outildepilotage_button_576162";
    item['fr'] = "Commencer";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_textcopy4_403941";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_text_1034631";
    item['fr'] = "Confidentiel Neurowaste";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen9_text2_258286";
    item['fr'] = "Pilotage du parc";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen9_textcopy_455082";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen9_textcopy4_1040480";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen9_textcopy2_419023";
    item['fr'] = "Connecté en tant que";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen9_text_534236";
    item['fr'] = "Confidentiel Neurowaste";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen9_checkboxcopy_621024";
    item['fr'] = "Pleines";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen9_field_489190";
    item['fr'] = "seuil";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen9_textcopy3_103325";
    item['fr'] = "%";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen9_text3_702453";
    item['fr'] = "Seuil critique";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f1_pleincopy_210175";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f1_futurecopy_288848";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f1_nvocopy_903750";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f5_pleincopy_187093";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f5_futurecopy_273025";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f5_nvocopy_422382";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f4_pleincopy_41193";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f4_nvocopy_245405";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f4_futurecopy_890050";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f3_pleincopy_1020683";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f3_futurecopy_719565";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f3_nvocopy_659395";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f2_pleincopy_404154";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f2_nvocopy_886807";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f2_futurecopy_215022";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f6_plein_157580";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f6_nvo_1018809";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f6_future_19636";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f10_plein_262651";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f10_nvo_883821";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f10_future_977647";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f9_plein_512305";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f9_future_555024";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f9_nvo_661293";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f8_plein_537803";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f8_nvo_39076";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f8_future_527255";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f7_plein_898771";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f7_future_1029799";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f7_nvo_147430";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f6_pleincopy_438498";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f6_nvocopy_340390";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f6_futurecopy_280310";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f10_pleincopy_462262";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f10_nvocopy_544582";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f10_futurecopy_656154";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f9_pleincopy_994997";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f9_futurecopy_695666";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f9_nvocopy_799065";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f8_pleincopy_669619";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f8_nvocopy_1040629";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f8_futurecopy_329613";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f7_pleincopy_449776";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f7_futurecopy_1032782";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f7_nvocopy_298249";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future_f6_futurecopy_975032";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future_f10_futurecopy_562029";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future_f9_futurecopy_1038683";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future_f8_futurecopy_824122";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "future_f7_futurecopy_492044";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future_f6_futurecopy2_258430";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f6_nvocopy2_483315";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "synthese_text_148946";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_hotspot_753190";
    item['fr'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein_site_263000";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein_tot_plein_129944";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_tot_vide_659065";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_tps_ecoule_168274";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "button1_title_377072";
    item['fr'] = "Part X";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_textcopy2_458308";
    item['fr'] = "Connecté en tant que";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_textcopy4_708368";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_textcopy_562953";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_text3_474323";
    item['fr'] = "Seuil critique";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_textcopy3_70586";
    item['fr'] = "%";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_field_578660";
    item['fr'] = "seuil";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_checkboxcopy_155495";
    item['fr'] = "Prévisions";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_checkbox_608856";
    item['fr'] = "Pleines";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_text_728825";
    item['fr'] = "Confidentiel Neurowaste";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_long_term2_textcopy2_670938";
    item['fr'] = "Connecté en tant que";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_long_term2_textcopy4_334237";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_long_term2_textcopy_878102";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_long_term2_text_835376";
    item['fr'] = "Confidentiel Neurowaste";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_textcopy2_298203";
    item['fr'] = "Connecté en tant que";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_textcopy4_283512";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_textcopy_857282";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_text3_718061";
    item['fr'] = "Seuil critique";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_textcopy3_42693";
    item['fr'] = "%";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_field_801030";
    item['fr'] = "seuil";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_checkboxcopy_976241";
    item['fr'] = "Pleines";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_text_770241";
    item['fr'] = "Confidentiel Neurowaste";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_sup_seuil_199815";
    item['fr'] = "90";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f7_futurecopy_1008696";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f8_futurecopy_447599";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f9_futurecopy_151885";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f10_futurecopy_992163";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f6_futurecopy_1016054";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f6_future_409611";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f10_future_450967";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f9_future_641658";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f8_future_264407";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f7_future_932286";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_button2_298091";
    item['fr'] = "Dernier relevé";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_text_728181";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_f6_futurecopy2_732794";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_f6_futurecopy3_342735";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_f6_futurecopy4_504608";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_f6_futurecopy5_696332";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_f6_futurecopy6_880620";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_f6_futurecopy7_758001";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_f6_futurecopy8_412273";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_f6_futurecopy9_781917";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_f6_futurecopy10_1030525";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen13_text_324628";
    item['fr'] = "Confidentiel Neurowaste";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen13_checkbox_466455";
    item['fr'] = "Prévisions";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen13_checkboxcopy_395240";
    item['fr'] = "Pleines";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen13_field_892873";
    item['fr'] = "seuil";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen13_textcopy3_386867";
    item['fr'] = "%";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen13_text3_131452";
    item['fr'] = "Seuil critique";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen13_textcopy_301572";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen13_textcopy4_947374";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen13_textcopy2_824664";
    item['fr'] = "Connecté en tant que";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain2_text_989597";
    item['fr'] = "Confidentiel Neurowaste";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain2_textcopy_890192";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain2_textcopy4_272253";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain2_textcopy2_519500";
    item['fr'] = "Connecté en tant que";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_long_term2_text_953382";
    item['fr'] = "Confidentiel Neurowaste";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_long_term2_textcopy_431061";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_long_term2_textcopy4_194079";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_long_term2_textcopy2_348661";
    item['fr'] = "Connecté en tant que";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_tot_vide_513126";
    item['fr'] = "false";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_tot_pleincopy_619782";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_tot_dechcopy_715061";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy13_717033";
    item['fr'] = "MATIERES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy12_631854";
    item['fr'] = "VIDES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy11_388492";
    item['fr'] = "30 m3";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy10_450151";
    item['fr'] = "10 m3";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy9_1000207";
    item['fr'] = "DIB";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy8_432701";
    item['fr'] = "VEGET";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy7_1026682";
    item['fr'] = "PLATRE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy6_465025";
    item['fr'] = "PAPIER";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy5_399232";
    item['fr'] = "GRAV NV";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_text_274378";
    item['fr'] = "MEUBLES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy_1004939";
    item['fr'] = "BOIS";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy2_609884";
    item['fr'] = "CARTON";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy3_911667";
    item['fr'] = "FERRAILLE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy4_888956";
    item['fr'] = "GRAV";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy3_877725";
    item['fr'] = "%";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_field_257649";
    item['fr'] = "ratio";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_text3_839193";
    item['fr'] = "Bennes par chauffeur";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_chauffeurs_dech_529003";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_tot_dech_407908";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_tot_plein_484888";
    item['fr'] = "DI";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_text_879234";
    item['fr'] = "TOTAL";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_f6_futurecopy10_122170";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_f6_futurecopy9_698152";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_f6_futurecopy8_246901";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_f6_futurecopy7_1046560";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_f6_futurecopy6_721500";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_f6_futurecopy5_253821";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_f6_futurecopy4_402338";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_f6_futurecopy3_247506";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_f6_futurecopy2_796751";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_f6_futurecopy_415245";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "footer_previ_textcopy_997137";
    item['fr'] = "TOTAL DI et Stock";
    
    item = {};
    this.items.push(item);
    item['key'] = "footer_previ_chauffeurs_dechcopy_872235";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy3_695388";
    item['fr'] = "Commandes DI";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_fieldcopy_694154";
    item['fr'] = "DI";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy_238384";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy4_872266";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_long_term_textcopy_205837";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_long_term_textcopy4_1008537";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy3_442342";
    item['fr'] = "%";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_field_450606";
    item['fr'] = "seuil";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_text3_113121";
    item['fr'] = "Seuil critique";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy3_894942";
    item['fr'] = "Stock restant";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_fieldcopy_605658";
    item['fr'] = "stock";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_bar_title_472889";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_bar_title_172007";
    item['fr'] = "Part X";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_bar_title_856915";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_bar_title_577939";
    item['fr'] = "Lendemain";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_bar_title_670042";
    item['fr'] = "Lendemain";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_bar_title_96254";
    item['fr'] = "Part X";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_bar_titlecopy2_334728";
    item['fr'] = "Long terme";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_bar_titlecopy3_609746";
    item['fr'] = "Pilotage";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_bar_titlecopy4_855091";
    item['fr'] = "Lendemain";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_bar_titlecopy5_313580";
    item['fr'] = "Lendemain";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_bar_titlecopy6_775003";
    item['fr'] = "Long terme";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_text_313449";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_text2_580094";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_textcopy_68660";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_textcopy2_11000";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "number_text_951361";
    item['fr'] = "test";
    
    item = {};
    this.items.push(item);
    item['key'] = "number_text_624628";
    item['fr'] = "test";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f10_nvocopy2_659117";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future_f10_futurecopy2_54581";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein_f5_pleincopy2_345270";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein_f5_pleincopy3_46544";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy11_594739";
    item['fr'] = "PRES VGT";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f10_nvocopy2_445333";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_tps_ecoule_163383";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f6_nvocopy2_783378";
    item['fr'] = "90";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f7_nvocopy_540080";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f8_nvocopy_695585";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f9_nvocopy_507285";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f10_nvocopy_936896";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f6_nvocopy_781245";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f6_nvo_268412";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f10_nvo_866342";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f9_nvo_150042";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f8_nvo_237889";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f7_nvo_711926";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy14_237874";
    item['fr'] = "AUTRES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy15_876455";
    item['fr'] = "PAM";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy16_557392";
    item['fr'] = "MEUBLES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy17_138200";
    item['fr'] = "COMPOST";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy18_373064";
    item['fr'] = "BLANC";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy19_1010719";
    item['fr'] = "AMIANTE";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_f5_pleincopy3_819773";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_f5_pleincopy2_174041";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_tot_plein_841847";
    item['fr'] = "false";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_site_576584";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_f2_pleincopy_711257";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_f3_pleincopy_704083";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_f4_pleincopy_237120";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_f5_pleincopy_1019169";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_f1_pleincopy_579817";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_f5_plein_913697";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_f4_plein_428828";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_f3_plein_515489";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_f2_plein_38033";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques_textcopy_765315";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques_textcopy4_744580";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques_text_361131";
    item['fr'] = "Confidentiel Neurowaste";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy19_398756";
    item['fr'] = "CAISSE\nGRILLAGE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy18_943871";
    item['fr'] = "CAISSE\nPALETTE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy17_533694";
    item['fr'] = "FUT";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy16_682551";
    item['fr'] = "MEUBLES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy15_617985";
    item['fr'] = "DEEE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy14_663910";
    item['fr'] = "VIDES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy11_382110";
    item['fr'] = "Ampoules";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy13_804733";
    item['fr'] = "FLUX";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy12_77233";
    item['fr'] = "VIDES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy10_601844";
    item['fr'] = "m3";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy9_162256";
    item['fr'] = "Solvants";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy8_711769";
    item['fr'] = "Phytos";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy7_846922";
    item['fr'] = "Pateux";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy6_329637";
    item['fr'] = "Medica-\nments";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy5_433775";
    item['fr'] = "Extincteurs";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_text_858687";
    item['fr'] = "Acides";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy_438087";
    item['fr'] = "Bases";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy2_377475";
    item['fr'] = "Aerosols";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy3_818974";
    item['fr'] = "Bidons d’huiles";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy4_540852";
    item['fr'] = "Comburants";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f10_nvocopy2_957203";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_tps_ecoule_391734";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f6_nvocopy2_213729";
    item['fr'] = "90";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f7_nvocopy_456091";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f8_nvocopy_804705";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f9_nvocopy_916670";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f10_nvocopy_176518";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f6_nvocopy_998172";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f6_nvo_419311";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f10_nvo_542196";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f9_nvo_343885";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f8_nvo_154584";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f7_nvo_577000";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy20_433997";
    item['fr'] = "EXTINCTEURS";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy21_918514";
    item['fr'] = "FILTRES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy22_678836";
    item['fr'] = "PATEUX";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy20_75635";
    item['fr'] = "Neons";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy21_616349";
    item['fr'] = "Bouteilles de gaz";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy22_869785";
    item['fr'] = "Cartouches";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy_837617";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy2_828913";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy3_623465";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy4_21111";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy5_870671";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy6_839384";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy7_671215";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy8_801853";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy9_98453";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy10_313984";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy10_560056";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy9_800636";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy8_187931";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy7_378120";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy6_76678";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy5_9025";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy4_171377";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy3_896297";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy2_477640";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy_684052";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoule_947602";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy10_345899";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy9_579528";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy8_921062";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy7_56330";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy6_785713";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy5_623281";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy4_459305";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy3_927687";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy2_414181";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy_168760";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoule_979473";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy10_433675";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy11_601769";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy12_570052";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy13_267965";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy_679544";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy2_921792";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy3_159511";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy10_435051";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy9_566459";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy8_540540";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy7_424911";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy6_247802";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy5_639185";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy4_1000454";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy3_374950";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy2_664053";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy_240699";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoule_512234";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy_563460";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy2_415337";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy3_315269";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_button2_787548";
    item['fr'] = "J+2 et J+3";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_text2_793926";
    item['fr'] = "Bennes pleines sur tout le parc";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy7_673778";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy8_726052";
    item['fr'] = "Commandes de DI";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy9_220639";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy10_52860";
    item['fr'] = "Prédiction j+…";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy11_555809";
    item['fr'] = "-";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy12_491163";
    item['fr'] = "Tolérance stock restant";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy13_351121";
    item['fr'] = "Objectif pleines non vides";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy13_545773";
    item['fr'] = "Nombre de bennes par chauffeur";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy14_463890";
    item['fr'] = "=";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy15_504053";
    item['fr'] = "Nombre de chauffeurs";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy16_1026537";
    item['fr'] = "Bennes pleines sur tout le parc";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy17_435706";
    item['fr'] = "Commandes de DI";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy18_924415";
    item['fr'] = "Objectif pleines non vides";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy19_642910";
    item['fr'] = "Prédiction j+…";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy20_514358";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy21_14398";
    item['fr'] = "-";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy22_479703";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy23_818187";
    item['fr'] = "Nombre de bennes par chauffeur";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy24_1005567";
    item['fr'] = "=";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy25_265597";
    item['fr'] = "Nombre de chauffeurs";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy16_565123";
    item['fr'] = "Bennes pleines sur tout le parc";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy17_125489";
    item['fr'] = "Commandes de DI";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy18_429828";
    item['fr'] = "Objectif pleines non vides";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy19_876822";
    item['fr'] = "Prédiction j+…";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy20_876137";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy21_274884";
    item['fr'] = "-";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy22_90120";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy23_51963";
    item['fr'] = "Nombre de bennes par chauffeur";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy24_782920";
    item['fr'] = "=";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy25_1029789";
    item['fr'] = "Nombre de chauffeurs";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_field2_796664";
    item['fr'] = "Pleines sur tout le parc";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_fieldcopy4_100599";
    item['fr'] = "Commandes DI";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_fieldcopy5_707598";
    item['fr'] = "Tolérance stock restant";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_fieldcopy6_661616";
    item['fr'] = "Bennes par chauffeur";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_text4_736810";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy16_1022436";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy17_500197";
    item['fr'] = "-";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy18_862397";
    item['fr'] = "-";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy18_906400";
    item['fr'] = "=";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy19_1030879";
    item['fr'] = "Nombre de chauffeurs";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy20_834755";
    item['fr'] = "Prédiction j+…";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy21_87803";
    item['fr'] = "Prédiction j+…";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy22_13450";
    item['fr'] = "=";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy23_795472";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_fieldcopy7_69627";
    item['fr'] = "Pleines sur tout le parc";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_fieldcopy8_363594";
    item['fr'] = "Commandes DI";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_fieldcopy9_357086";
    item['fr'] = "Tolérance stock restant";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_fieldcopy10_415848";
    item['fr'] = "Bennes par chauffeur";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy24_44714";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy25_571052";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy26_273007";
    item['fr'] = "-";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_text5_747381";
    item['fr'] = "Prévisions J+2 et J+3";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_button2_570492";
    item['fr'] = "Calculer J+2";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_buttoncopy_650350";
    item['fr'] = "Calculer J+3";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy4_964709";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy7_783543";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy10_340354";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy11_858541";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy_217560";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy10_617275";
    item['fr'] = "Autres";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy12_355291";
    item['fr'] = "Radio-\ngraphies";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy15_770233";
    item['fr'] = "Piles";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy16_355322";
    item['fr'] = "Vide 80L";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy17_492775";
    item['fr'] = "Vide 650L";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy17_503020";
    item['fr'] = "Vide rouge";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy16_370390";
    item['fr'] = "Vide Grise";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy15_615643";
    item['fr'] = "Piles";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy12_933440";
    item['fr'] = "Radiographies";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy10_931730";
    item['fr'] = "Autres";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy22_1025645";
    item['fr'] = "Cartouches";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy21_451631";
    item['fr'] = "Bouteilles de gaz";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy20_1013292";
    item['fr'] = "Neons";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy14_697025";
    item['fr'] = "VIDES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy11_565988";
    item['fr'] = "Caisses vides";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy13_242419";
    item['fr'] = "FLUX";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy9_134139";
    item['fr'] = "Solvants";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy8_673542";
    item['fr'] = "Autres \nliquides";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy7_980115";
    item['fr'] = "Pateux";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy6_140785";
    item['fr'] = "Pateux";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy5_454172";
    item['fr'] = "Emballages";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_text_268660";
    item['fr'] = "Phyto";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy_574199";
    item['fr'] = "Flitres à huiles";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy2_471369";
    item['fr'] = "Acides";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy3_843596";
    item['fr'] = "Bases";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy4_136036";
    item['fr'] = "Aerosols";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques_text2_844727";
    item['fr'] = "Spécifiques hors ECO DDS";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques_textcopy2_378428";
    item['fr'] = "Spécifiques ECO DDS";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques_textcopy3_126424";
    item['fr'] = "Pneus";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy10_691848";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy9_712096";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy8_299244";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy7_524033";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy6_899035";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy5_24441";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy4_161823";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy3_968867";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy2_1004298";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy_385970";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoule_1019865";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds2_textcopy11_275616";
    item['fr'] = "VIDES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds2_textcopy13_495019";
    item['fr'] = "FLUX";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds2_textcopy8_1014316";
    item['fr'] = "Autres";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds2_textcopy7_818268";
    item['fr'] = "Pateux";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds2_textcopy6_151906";
    item['fr'] = "Pateux";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds2_textcopy5_1024740";
    item['fr'] = "Emballages";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds2_text_454272";
    item['fr'] = "Jantes";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds2_textcopy_335088";
    item['fr'] = "Pneus decret";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds2_textcopy2_522692";
    item['fr'] = "Pneus";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds2_textcopy3_347688";
    item['fr'] = "Velo";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds2_textcopy4_709500";
    item['fr'] = "Aerosols";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds2_tps_ecoulecopy10_474495";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds2_tps_ecoulecopy9_238838";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds2_tps_ecoulecopy8_27992";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds2_tps_ecoulecopy7_743862";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds2_tps_ecoulecopy6_75771";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds2_tps_ecoulecopy5_496928";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds2_tps_ecoulecopy4_903381";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds2_tps_ecoulecopy3_788303";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds2_tps_ecoulecopy2_129212";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds2_tps_ecoulecopy_540410";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds2_tps_ecoule_138060";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_pneus2_textcopy13_668850";
    item['fr'] = "FLUX";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_pneus2_text_1004619";
    item['fr'] = "Froid";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_pneus2_textcopy_701285";
    item['fr'] = "TV / DEEE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_pneus2_textcopy2_683764";
    item['fr'] = "Ampoules";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_pneus2_textcopy3_903080";
    item['fr'] = "Neons";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_pneus2_tps_ecoulecopy10_1011538";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_pneus2_tps_ecoulecopy3_712126";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_pneus2_tps_ecoulecopy2_1039726";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_pneus2_tps_ecoulecopy_494062";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_pneus2_tps_ecoule_201900";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_deee_textcopy4_401490";
    item['fr'] = "Caisse grillage vides";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_deee_tps_ecoulecopy4_324076";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_deee2_textcopy4_945343";
    item['fr'] = "Autres";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_deee2_textcopy13_691451";
    item['fr'] = "FLUX";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_deee2_text_991329";
    item['fr'] = "Piles";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_deee2_textcopy_875404";
    item['fr'] = "Batteries";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_deee2_textcopy2_800565";
    item['fr'] = "Bouteilles de gaz";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_deee2_textcopy3_529433";
    item['fr'] = "Radio-\ngraphies";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_deee2_tps_ecoulecopy4_525870";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_deee2_tps_ecoulecopy10_244863";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_deee2_tps_ecoulecopy3_209402";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_deee2_tps_ecoulecopy2_85953";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_deee2_tps_ecoulecopy_1003927";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_deee2_tps_ecoule_388302";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_autres_textcopy5_1012482";
    item['fr'] = "Cartouches d’encres";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_autres_tps_ecoulecopy5_90317";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques_textcopy5_1030865";
    item['fr'] = "DEEE";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques_textcopy6_899097";
    item['fr'] = "Autres";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_textcopy4_264696";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_textcopy_948030";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_text3_84145";
    item['fr'] = "Seuil critique";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_field_452147";
    item['fr'] = "seuil";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_textcopy3_330655";
    item['fr'] = "%";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_checkboxcopy_916268";
    item['fr'] = "Pleines";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_text_364324";
    item['fr'] = "Confidentiel Neurowaste";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques2_textcopy4_416116";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques2_textcopy_505692";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques2_text2_492728";
    item['fr'] = "Spécifiques hors ECO DDS";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques2_textcopy2_431728";
    item['fr'] = "Spécifiques ECO DDS";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques2_textcopy3_25262";
    item['fr'] = "Pneus";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques2_textcopy5_607401";
    item['fr'] = "DEEE";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques2_textcopy6_968379";
    item['fr'] = "Autres";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques2_text_725259";
    item['fr'] = "Confidentiel Neurowaste";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_deee_textcopy5_706121";
    item['fr'] = "Extincteur\n< 2kg";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_deee_tps_ecoulecopy5_879368";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy10_787892";
    item['fr'] = "DASRI";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy11_127858";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_text2_447381";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_previ_textcopy10_10317";
    item['fr'] = "PRESTATIARES";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_textcopy3_281539";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_textcopy4_51890";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy12_733465";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy13_799918";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy12_1028957";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy11_246163";
    item['fr'] = "Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy12_685523";
    item['fr'] = "Heure";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy11_100242";
    item['fr'] = "Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy12_142414";
    item['fr'] = "Heure";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy13_11033";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy12_998192";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_pneus_textcopy9_504276";
    item['fr'] = "Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_pneus_textcopy12_877558";
    item['fr'] = "Heure";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_pneus_tps_ecoulecopy13_21766";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_pneus_tps_ecoulecopy12_409688";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_deee_textcopy9_134103";
    item['fr'] = "Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_deee_textcopy12_868566";
    item['fr'] = "Heure";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_deee_tps_ecoulecopy13_92653";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_deee_tps_ecoulecopy12_957789";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_autres_textcopy9_922433";
    item['fr'] = "Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_autres_textcopy12_209886";
    item['fr'] = "Heure";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_autres_tps_ecoulecopy13_438016";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_autres_tps_ecoulecopy12_601089";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "legende_text_896716";
    item['fr'] = "Légende";
    
    item = {};
    this.items.push(item);
    item['key'] = "legende_text2_135319";
    item['fr'] = "Bennes à quai au dessus du seuil critique";
    
    item = {};
    this.items.push(item);
    item['key'] = "legende_textcopy_182157";
    item['fr'] = "Bennes vides HORS QUAI";
    
    item = {};
    this.items.push(item);
    item['key'] = "legende_textcopy2_187745";
    item['fr'] = "Bennes pleines HORS QUAI";
    
    item = {};
    this.items.push(item);
    item['key'] = "legende_textcopy3_614303";
    item['fr'] = "Heure du dernier relevé";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_f6_nvocopy_825303";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f7_nvocopy2_361790";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp_niveau_text_987701";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "remonte_terrain_text_348232";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_f7_nvocopy2_683165";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_f7_nvocopy_191728";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp_niveau_text_760061";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp_niveau_f6_nvocopy_408454";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_text_864557";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f6_nvocopy_581957";
    item['fr'] = "90";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp_niveau_textcopy_532408";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "legende_text3_241201";
    item['fr'] = "Demande packmat";
    
    item = {};
    this.items.push(item);
    item['key'] = "legende_textcopy4_632920";
    item['fr'] = "Demande 32T";
    
    item = {};
    this.items.push(item);
    item['key'] = "legende_textcopy5_353281";
    item['fr'] = "Pleines hors quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "legende_textcopy6_776692";
    item['fr'] = "A quai de ce flux (vide = 1)";
    
    item = {};
    this.items.push(item);
    item['key'] = "legende_textcopy7_270251";
    item['fr'] = "Remplissage cumulé du flux (%)";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_checkbox_772893";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy20_160323";
    item['fr'] = "VU";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_field_624129";
    item['fr'] = "Email";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_fieldcopy_516794";
    item['fr'] = "Mot de passe";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_button_809563";
    item['fr'] = "Connexion";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy7_20642";
    item['fr'] = "Comburants";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_iconbutton_1879671726";
    item['fr'] = "Pleines";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_iconbutton_1145326881";
    item['fr'] = "Pleines hq";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_iconbuttoncopy_441294271";
    item['fr'] = "Pleines hq";
    
    item = {};
    this.items.push(item);
    item['key'] = "pleines_check2_iconbuttoncopy_1326723140";
    item['fr'] = "Prévisions";
    
    item = {};
    this.items.push(item);
    item['key'] = "pleines_check2_iconbutton_985962333";
    item['fr'] = "Prévisions";
    
    item = {};
    this.items.push(item);
    item['key'] = "previsions_check2_iconbuttoncopy_1592091735";
    item['fr'] = "Prévisions";
    
    item = {};
    this.items.push(item);
    item['key'] = "previsions_check2_iconbutton_911481139";
    item['fr'] = "Prévisions";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy15_2040128122";
    item['fr'] = "Passage\nchimirec";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_2_tps_ecoulecopy2_730848157";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_lendemain_text_1512351266";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_lendemain_text2_1622512372";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_previ_textcopy11_160941081";
    item['fr'] = "PAM";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_previ_textcopy12_993304353";
    item['fr'] = "COMPOST";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_previ_textcopy14_410820618";
    item['fr'] = "AMIANTE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_previ_textcopy15_2008838923";
    item['fr'] = "BLANC";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_previ_textcopy16_517914095";
    item['fr'] = "MEUBLES";
    
    item = {};
    this.items.push(item);
    item['key'] = "footer_previ_button_941959767";
    item['fr'] = "Export excel";
    
    item = {};
    this.items.push(item);
    item['key'] = "footer_previ_chauffeurs_dechcopy2_396861314";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "footer_previ_chauffeurs_dechcopy3_719331210";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "outildashboard_button_722986947";
    item['fr'] = "Commencer";
    
    item = {};
    this.items.push(item);
    item['key'] = "outildashboard_textcopy2_1375414412";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "outildashboard_textcopy_1639957465";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "outildashboard_text_873460681";
    item['fr'] = "Bienvenue";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_button_397447829";
    item['fr'] = "Commencer";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_textcopy2_1905495570";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_textcopy_1016018280";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_bienvenue_2129009774";
    item['fr'] = "Bienvenue";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_bienvenuecopy_575674823";
    item['fr'] = "Authentification en cours…";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_user_namecopy_1028325999";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_non_previ_user_name_171388901";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_non_previ_entreprise_632859967";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_text2_16020052";
    item['fr'] = "Bennes pleines sur tout le parc";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy8_1074956142";
    item['fr'] = "Commandes de DI";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy12_305776182";
    item['fr'] = "Tolérance stock restant";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy10_1090449136";
    item['fr'] = "Prédiction j+…";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy9_927996986";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy11_1915177039";
    item['fr'] = "-";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy7_689574863";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy13_1420085349";
    item['fr'] = "Nombre de bennes par chauffeur";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy14_1517947394";
    item['fr'] = "=";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy15_2081399699";
    item['fr'] = "Nombre de chauffeurs";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy20_1018759506";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy18_180118168";
    item['fr'] = "=";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy19_1683238856";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_field2_1844914589";
    item['fr'] = "Pleines sur tout le parc";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_fieldcopy4_164027943";
    item['fr'] = "Commandes DI";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_fieldcopy5_223313662";
    item['fr'] = "Tolérance stock restant";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_fieldcopy6_788842960";
    item['fr'] = "Bennes par chauffeur";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_text4_464687718";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy16_164080818";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy17_815093258";
    item['fr'] = "-";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_button2_1342511280";
    item['fr'] = "Calculer J+2";
    
    item = {};
    this.items.push(item);
    item['key'] = "j3_field2_362777514";
    item['fr'] = "Pleines sur tout le parc";
    
    item = {};
    this.items.push(item);
    item['key'] = "j3_text4_787922685";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "j3_fieldcopy4_61668733";
    item['fr'] = "Commandes DI";
    
    item = {};
    this.items.push(item);
    item['key'] = "j3_textcopy16_1645477425";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "j3_textcopy20_1750595058";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "j3_textcopy17_1423335219";
    item['fr'] = "-";
    
    item = {};
    this.items.push(item);
    item['key'] = "j3_fieldcopy5_207372580";
    item['fr'] = "Tolérance stock restant";
    
    item = {};
    this.items.push(item);
    item['key'] = "j3_fieldcopy6_861174974";
    item['fr'] = "Bennes par chauffeur";
    
    item = {};
    this.items.push(item);
    item['key'] = "j3_textcopy18_1922334213";
    item['fr'] = "=";
    
    item = {};
    this.items.push(item);
    item['key'] = "j3_textcopy19_55985699";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "j3_button2_1246365166";
    item['fr'] = "Calculer J+3";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy20_1686341561";
    item['fr'] = "Huile \nvegetale";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy19_427316740";
    item['fr'] = "Emballages \nsales";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy18_358245029";
    item['fr'] = "Non\nIdentifiés";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy15_1152060659";
    item['fr'] = "Passage\nchimirec";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy12_386393159";
    item['fr'] = "Heure";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy11_1202811286";
    item['fr'] = "Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy10_1639163830";
    item['fr'] = "DASRI";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy17_1119390205";
    item['fr'] = "Vide 650L";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy16_1720125253";
    item['fr'] = "Vide 80L";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy14_1651934584";
    item['fr'] = "VIDES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy13_1923923565";
    item['fr'] = "FLUX";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy9_119362228";
    item['fr'] = "Solvants";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy8_1196104008";
    item['fr'] = "Phytos";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy7_59175588";
    item['fr'] = "Pateux";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy6_877969186";
    item['fr'] = "Medica-\nments";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy5_124775719";
    item['fr'] = "Extincteurs";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_text_507203747";
    item['fr'] = "Acides";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy_1803254367";
    item['fr'] = "Bases";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy2_1165943392";
    item['fr'] = "Aerosols";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy3_2109369346";
    item['fr'] = "Bidons d’huiles";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy4_381831549";
    item['fr'] = "Comburants";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_2_tps_ecoulecopy5_1215588778";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_2_tps_ecoulecopy4_788702946";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_2_tps_ecoulecopy3_1567439975";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_2_tps_ecoulecopy2_1797703994";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_2_tps_ecoulecopy_1330341284";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_2_tps_ecoulecopy7_2073976681";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy12_665141061";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy13_1905962331";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy10_648967055";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy9_242263340";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy8_1213364409";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy7_228323772";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy6_1810285137";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy5_220678052";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy4_167467443";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy3_107087291";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy2_246595985";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy_848264162";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoule_202546964";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy7_1318320609";
    item['fr'] = "Comburants";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy12_749883014";
    item['fr'] = "Heure";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy9_1738026276";
    item['fr'] = "Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy11_1407128508";
    item['fr'] = "Caisses vides";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy13_1536363329";
    item['fr'] = "FLUX";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy8_429637179";
    item['fr'] = "Autres \nliquides";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy6_1739170358";
    item['fr'] = "Pateux";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy5_534968501";
    item['fr'] = "Combus-\ntibles";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_text_403019025";
    item['fr'] = "Phyto";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy_1052262556";
    item['fr'] = "Flitres à huiles";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy2_1998163003";
    item['fr'] = "Acides";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy3_838023052";
    item['fr'] = "Bases";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy4_1903129762";
    item['fr'] = "Aerosols";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_textcopy5_1984123110";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_tot_vide_45776313";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_text_567409018";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_textcopy4_785087973";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_textcopy3_573069486";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_text2_395814149";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_textcopy_1661104960";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_textcopy2_2007780252";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_textcopy5_1037720880";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_textcopy4_960834266";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_textcopy3_1830069848";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_textcopy2_788456716";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_textcopy_2062629928";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_text_1331388545";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "creation_ot_text_641736792";
    item['fr'] = "Création des feuilles de tournée";
    
    item = {};
    this.items.push(item);
    item['key'] = "creation_ot_textcopy_126508627";
    item['fr'] = "OT présélectionnés";
    
    item = {};
    this.items.push(item);
    item['key'] = "creation_ot_textcopy2_1343124750";
    item['fr'] = "Feuille de tournée sélectionnée";
    
    item = {};
    this.items.push(item);
    item['key'] = "creation_ot_textcopy3_2035429896";
    item['fr'] = "Tournée sélectionnée";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_textcopy5_795149269";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_textcopy6_1842674847";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "footer_previ_checkbox_1664954967";
    item['fr'] = "Inclure hors quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "footer_previ_text2_568714334";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_textcopy5_1710572791";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "creation_ot_textcopy4_1651706641";
    item['fr'] = "OT présélectionnés";
    
    item = {};
    this.items.push(item);
    item['key'] = "creation_ot_textcopy4_2056651706";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy_1277832216";
    item['fr'] = "OT présélectionnés";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy2_1118312604";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy3_1939593427";
    item['fr'] = "Matière";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy4_1572732253";
    item['fr'] = "hh";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy5_1409858039";
    item['fr'] = "HQ";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy6_1666957777";
    item['fr'] = "Tournée";
    
    item = {};
    this.items.push(item);
    item['key'] = "button_tournee_button2_1540574351";
    item['fr'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "creation_ot_text2_449535278";
    item['fr'] = "Aucune";
    
    item = {};
    this.items.push(item);
    item['key'] = "creation_ot_text3_981386711";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_preselection2_textcopy6_726451278";
    item['fr'] = "Tournée";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_preselection2_textcopy5_1183971797";
    item['fr'] = "HQ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_preselection2_textcopy4_2086351441";
    item['fr'] = "HQ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_preselection2_textcopy3_1424572530";
    item['fr'] = "Matière";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_preselection2_textcopy2_1364499539";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_preselection2_textcopy_1554177015";
    item['fr'] = "Feuille de tournee";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot2_textcopy5_337249324";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot2_textcopy3_1918211709";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot2_textcopy2_2062498143";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot2_textcopy_652519627";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot2_text_1109436249";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot2_textcopy6_1545298874";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuprincipal_text_720642711";
    item['fr'] = "Sélection camion";
    
    item = {};
    this.items.push(item);
    item['key'] = "tournee_text_1956493907";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_text2_1965486916";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "tournee_j2_text_2114960831";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuprincipal_button_310198181";
    item['fr'] = "Tounée du jour";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuprincipal_buttoncopy_589642378";
    item['fr'] = "Tounée demain";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuprincipal_textcopy_281571160";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j2_textcopy3_106301";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j2_textcopy_359905";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j2_text_135904";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j2_text2_490477";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "phototicketpesee_button_667696";
    item['fr'] = "Confirmer";
    
    item = {};
    this.items.push(item);
    item['key'] = "phototicketpesee_capture_104296";
    item['fr'] = "Nouvelle photo";
    
    item = {};
    this.items.push(item);
    item['key'] = "phototicketpesee_button_277296";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "informationscamion_field_612192";
    item['fr'] = "Immatriculation";
    
    item = {};
    this.items.push(item);
    item['key'] = "informationscamion_fieldcopy_1004904";
    item['fr'] = "Nom";
    
    item = {};
    this.items.push(item);
    item['key'] = "informationscamion_fieldcopy2_522804";
    item['fr'] = "Essence";
    
    item = {};
    this.items.push(item);
    item['key'] = "informationscamion_button_32207";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "tourneedujour_button_168835";
    item['fr'] = "Fin de la tournée";
    
    item = {};
    this.items.push(item);
    item['key'] = "informationscamion2_field_450745";
    item['fr'] = "Immatriculation";
    
    item = {};
    this.items.push(item);
    item['key'] = "informationscamion2_fieldcopy_578669";
    item['fr'] = "Nom";
    
    item = {};
    this.items.push(item);
    item['key'] = "informationscamion2_fieldcopy2_73108";
    item['fr'] = "Essence";
    
    item = {};
    this.items.push(item);
    item['key'] = "informationscamion2_button_697211";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "phototicketpesee_buttoncopy_869397";
    item['fr'] = "Retour";
    
    item = {};
    this.items.push(item);
    item['key'] = "phototicketpesee_field_973198";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "phototicketpesee_fieldcopy_732727";
    item['fr'] = "Matière";
    
    item = {};
    this.items.push(item);
    item['key'] = "phototicketpesee_fieldcopy2_437330";
    item['fr'] = "Exutoire";
    
    item = {};
    this.items.push(item);
    item['key'] = "phototicketpesee_fieldcopy3_380396";
    item['fr'] = "Tonnage";
    
    item = {};
    this.items.push(item);
    item['key'] = "commentaireotchute_buttoncopy_467334";
    item['fr'] = "Retour";
    
    item = {};
    this.items.push(item);
    item['key'] = "commentaireotchute_button_397424";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "commentaireotchute_field_170025";
    item['fr'] = "Commentaire";
    
    item = {};
    this.items.push(item);
    item['key'] = "commentaireotchute_textarea_27162";
    item['fr'] = "Commentaire";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j1_textcopy2_381121";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_feuille_tournee_textcopy_316249";
    item['fr'] = "Exutoire";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j_textcopy2_278564";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "informationscamionfin_fieldcopy3_321181";
    item['fr'] = "Immatriculation";
    
    item = {};
    this.items.push(item);
    item['key'] = "informationscamionfin_fieldcopy3_807904";
    item['fr'] = "km";
    
    item = {};
    this.items.push(item);
    item['key'] = "phototicketpesee_fieldcopy4_146372";
    item['fr'] = "Tonnage";
    
    item = {};
    this.items.push(item);
    item['key'] = "informationscamiondebut_fieldcopy3_338503";
    item['fr'] = "km";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_exutoire_text_795585";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "phototicketpesee_fieldcopy_31357";
    item['fr'] = "Commentaire";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j_textcopy4_447310";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "relevepav_fieldcopy2_47369";
    item['fr'] = "Volume";
    
    item = {};
    this.items.push(item);
    item['key'] = "relevepav_text_186430";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "relevepav_textcopy_446278";
    item['fr'] = "EMBALLAGES";
    
    item = {};
    this.items.push(item);
    item['key'] = "relevepav_iconbutton_120441";
    item['fr'] = "Au même endroit";
    
    item = {};
    this.items.push(item);
    item['key'] = "relevepav_iconbuttoncopy_240183";
    item['fr'] = "Anomalies";
    
    item = {};
    this.items.push(item);
    item['key'] = "relevepav_meme_endroitcopy_773201";
    item['fr'] = "Colonnes";
    
    item = {};
    this.items.push(item);
    item['key'] = "anomalies_checkbox_382615";
    item['fr'] = "Débordement";
    
    item = {};
    this.items.push(item);
    item['key'] = "anomalies_checkboxcopy_519824";
    item['fr'] = "Colonne inaccessible";
    
    item = {};
    this.items.push(item);
    item['key'] = "anomalies_checkboxcopy2_60527";
    item['fr'] = "Détérioration";
    
    item = {};
    this.items.push(item);
    item['key'] = "anomalies_button_824597";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "relevepav_button_407477";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "relevepav_buttoncopy_922358";
    item['fr'] = "Retour";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_colonne_text_842994";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "relevepav_meme_endroitcopy_75140";
    item['fr'] = "Scanner";
    
    item = {};
    this.items.push(item);
    item['key'] = "scanner_text_56147";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "relevepav_fieldcopy_133166";
    item['fr'] = "Commentaire";
    
    item = {};
    this.items.push(item);
    item['key'] = "relevepav_checkbox_862808";
    item['fr'] = "Vidage";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_fieldcopy3_1008541";
    item['fr'] = "Tonnage";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_fieldcopy2_127236";
    item['fr'] = "Volume";
    
    item = {};
    this.items.push(item);
    item['key'] = "relevepav_textcopy2_256767";
    item['fr'] = "EMBALLAGES";
    
    item = {};
    this.items.push(item);
    item['key'] = "relevepav_textcopy2_1038173";
    item['fr'] = "Déclaration d’anomalies";
    
    item = {};
    this.items.push(item);
    item['key'] = "scanner_capturecopy_328427";
    item['fr'] = "Scanner l’étiquette";
    
    item = {};
    this.items.push(item);
    item['key'] = "neurowaste_text_43774";
    item['fr'] = "Bienvenue !";
    
    item = {};
    this.items.push(item);
    item['key'] = "neurowaste_button_442814";
    item['fr'] = "Scanner l’étiquette !";
    
    item = {};
    this.items.push(item);
    item['key'] = "conteneur_anomaliescopy_561961";
    item['fr'] = "Prochaine collecte";
    
    item = {};
    this.items.push(item);
    item['key'] = "conteneur_anomaliescopy2_716399";
    item['fr'] = "Consignes de tri";
    
    item = {};
    this.items.push(item);
    item['key'] = "tonnage_volume_text_399893";
    item['fr'] = "tonnes";
    
    item = {};
    this.items.push(item);
    item['key'] = "tonnage_volume_textcopy_826874";
    item['fr'] = "litres";
    
    item = {};
    this.items.push(item);
    item['key'] = "neurowaste_button_123952";
    item['fr'] = "Scanner l’étiquette";
    
    item = {};
    this.items.push(item);
    item['key'] = "neurowaste_button_970610";
    item['fr'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "conteneur_text2_862997";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "tag_anomalie_text_372640";
    item['fr'] = "Débordement";
    
    item = {};
    this.items.push(item);
    item['key'] = "tag_anomalie_text_75922";
    item['fr'] = "Détérioration";
    
    item = {};
    this.items.push(item);
    item['key'] = "tag_anomalie_text_464738";
    item['fr'] = "Inaccessible";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_checkboxcopy_850806";
    item['fr'] = "Débordement";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_checkboxcopy2_334129";
    item['fr'] = "Détérioration";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_checkboxcopy3_335152";
    item['fr'] = "Colonne inaccessible";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_checkboxcopy_918198";
    item['fr'] = "Débordement";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_checkboxcopy_682828";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_checkboxcopy2_682397";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_checkboxcopy3_273642";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_checkboxcopy_214398";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_anomalie_checkboxcopy_70090";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_anomalie_checkboxcopy_751858";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "appareilphoto_button_1018107";
    item['fr'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "conteneur_textcopy2_447587";
    item['fr'] = "EMBALLAGES";
    
    item = {};
    this.items.push(item);
    item['key'] = "conteneur_anomaliescopy3_438397";
    item['fr'] = "Niveau de remplissage";
    
    item = {};
    this.items.push(item);
    item['key'] = "conteneur_textcopy2_869825";
    item['fr'] = "Dans 3 jours";
  }

  getStringsByLanguage = () => {
    let stringsByLang = {};
    for (let row of this.items) {
      const locKey = row.key;
      for (let key in row) {
        if (key === 'key')
          continue;
        let langObj = stringsByLang[key] || {};
        langObj[locKey] = row[key];
        stringsByLang[key] = langObj;
      }
    }
    return stringsByLang;
  }

}

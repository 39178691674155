import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import './App.css';
import NeurowasteScreen from './NeurowasteScreen.js';
import ConteneurScreen from './ConteneurScreen.js';
import AppareilPhotoScreen from './AppareilPhotoScreen.js';
import DataSheet_localizationSheet from './DataSheet_localizationSheet.js';
import DataSheet_matieres from './DataSheet_matieres.js';
import DataSheet_exutoires from './DataSheet_exutoires.js';
import DataSheet_selected_column from './DataSheet_selected_column.js';
import DataSheet_tri from './DataSheet_tri.js';
import firebase from 'firebase';
import firestore from 'firebase/firestore';


export default class App extends Component {
  constructor(props) {
    super(props);

    this.dataSheets = {};
    this.dataSheets['localizationSheet'] = new DataSheet_localizationSheet('localizationSheet', this.dataSheetDidUpdate);
    this.dataSheets['matieres'] = new DataSheet_matieres('matieres', this.dataSheetDidUpdate);
    this.dataSheets['exutoires'] = new DataSheet_exutoires('exutoires', this.dataSheetDidUpdate);
    this.dataSheets['selected_column'] = new DataSheet_selected_column('selected_column', this.dataSheetDidUpdate);
    this.dataSheets['tri'] = new DataSheet_tri('tri', this.dataSheetDidUpdate);
    this.dataSheetLoaded = {};

    this.dataSlots = {};
    this.dataSlots['ds_activeLang'] = "fr";
    this.dataSlots['ds_site_id'] = "null";
    this.dataSlots['ds_entreprise_id'] = "OpeO3uU5531XtXARYj7A";
    this.dataSlots['ds_username'] = "";
    this.dataSlots['ds_email'] = "";
    this.dataSlots['ds_image'] = "";
    this.dataSlots['ds_userid'] = "";
    this.dataSlots['ds_entreprise'] = "";
    this.dataSlots['ds_right_forecast'] = "";
    this.dataSlots['ds_admin'] = "";
    this.dataSlots['ds_login_success_screen'] = "connexion";
    this.dataSlots['ds_login_screen_state'] = "0";
    this.dataSlots['ds_demain_minuit_ts'] = "";
    this.dataSlots['ds_selected_tournee'] = "1";
    this.dataSlots['ds_today_minuit_ts'] = "";
    this.dataSlots['ds_saved_picture'] = "";
    this.dataSlots['ds_saved_picture_document_key'] = "";
    this.dataSlots['ds_state_camera'] = "0";
    this.dataSlots['ds_site_enlevement'] = "";
    this.dataSlots['ds_matiere_enlevement'] = "";
    this.dataSlots['ds_exutoire_enlevement'] = "";
    this.dataSlots['ds_tonnage_enlevement'] = "";
    this.dataSlots['ds_commentaire'] = "";
    this.dataSlots['ds_selected_ot'] = "";
    this.dataSlots['ds_volume_enlevement'] = "";
    this.dataSlots['ds_anomalie_debordement'] = "";
    this.dataSlots['ds_anomalie_acces'] = "";
    this.dataSlots['ds_anomalie_deteriore'] = "";
    this.dataSlots['ds_anomalies'] = "";
    this.dataSlots['ds_state'] = "0";
    this.dataSlots['ds_state_autres'] = "0";
    this.dataSlots['ds_mode_collecte'] = "0";
    this.dataSlots['ds_address'] = "";
    this.dataSlots['ds_scanner'] = "";
    this.dataSlots['ds_scanned_id'] = "NWNWA0001";
    this.dataSlots['ds_vidage'] = "false";
    this.dataSlots['ds_from_ots'] = "false";

    this.updateLocalizationFromDataSheet(this.dataSheets['localizationSheet']);


    // Initialize web service plugin 'pointapp'
    firebase.initializeApp({
    apiKey: "AIzaSyCOmPscdkTQKyChJUcPJTDDtC56nv8Ar3k",
    authDomain: "neurowastepoint.firebaseapp.com",
    databaseURL: "https://neurowastepoint.firebaseio.com",
    projectId: "neurowastepoint",
    storageBucket: "neurowastepoint.appspot.com",
    messagingSenderId: "399083467032"
    });
    firebase.firestore().settings({});
    
    this.serviceOptions_matieres = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/flux",
      query: "",
    };
    this.dataSheets['matieres'].appActions = this;
    this.dataSheets['matieres'].firebase = firebase;
    
    this.serviceOptions_exutoires = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/exutoires",
      query: "",
    };
    this.dataSheets['exutoires'].appActions = this;
    this.dataSheets['exutoires'].firebase = firebase;
    
    this.serviceOptions_selected_column = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/columns",
      query: "where('id_scan', '==', '$slot('ds_scanned_id')')",
    };
    this.dataSheets['selected_column'].appActions = this;
    this.dataSheets['selected_column'].firebase = firebase;
    

    this.state = {
      currentScreen: 'neurowaste',
      currentScreenProps: {},
      screenTransitionForward: true,
    }
    this.screenHistory = [ {...this.state} ];

  }

  windowDidResize = () => {
    let w = window.innerWidth;
    let formatId;
    if (w < 576) formatId = 'narrow-phone';
    else if (w < 768) formatId = 'wide-phone';
    else if (w < 1024) formatId = 'narrow-tablet';
    else formatId = 'wide-tablet';
    if (formatId !== this.state.screenFormatId) {
      this.setState({screenFormatId: formatId});
    }
  }

  componentDidMount() {
    this.windowDidResize();
    window.addEventListener('resize', this.windowDidResize);

    this.serviceOptions_selected_column.servicePath = this.dataSheets['selected_column'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/columns", this.dataSlots);
    this.loadData_pointapp(this.dataSheets['selected_column'], this.serviceOptions_selected_column, true);
    
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowDidResize);
  }

  isLoading() {
    return this.state.loading;
  }

  goToScreen = (screenId, props) => {
    // This method is the default implementation and could be customized by a navigation plugin.

    let screenIdx = -1;  // Check if the screen is already in the history stack, and pop back if so
    for (let i = 0; i < this.screenHistory.length; i++) {
      if (this.screenHistory[i].currentScreen === screenId) {
        screenIdx = i;
        break;
      }
    }
    if (screenIdx > -1) {
      this.screenHistory.splice(screenIdx + 1, this.screenHistory.length - screenIdx - 1);
      let prevScreenState = this.screenHistory[screenIdx];
      this.setState({...prevScreenState, screenTransitionForward: false});
    }
    else {
      props = props || {};
      let screenState = {currentScreen: screenId, currentScreenProps: props};
      this.screenHistory.push(screenState);
      this.setState({...screenState, screenTransitionForward: true});
    }
    window.scrollTo(0, 0);
  }

  goBack = () => {
    // This method is the default implementation and could be customized by a navigation plugin.
    if (this.screenHistory.length < 2)
      return;

    this.screenHistory.splice(this.screenHistory.length - 1, 1);
    let prevScreenState = this.screenHistory[this.screenHistory.length - 1];
    this.setState({...prevScreenState, screenTransitionForward: false});
    window.scrollTo(0, 0);
  }

  getDataSheet = (sheetId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    return this.dataSheets[sheetId];
  }

  addToDataSheet = (sheetId, newRow, actionId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    let sheet = this.dataSheets[sheetId];
    if (sheet && newRow) {
      sheet.addItem(newRow, this['serviceOptions_'+sheetId] || {});
    }
    this.setState({});
  }

  updateInDataSheet = (sheetId, row, actionId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    let sheet = this.dataSheets[sheetId];
    if (sheet && row) {
      sheet.replaceItemByKey(row.key, row, this['serviceOptions_'+sheetId] || {});

      if (this.state.currentScreenProps.dataSheetRow) {
        let screenProps = {...this.state.currentScreenProps};
        screenProps.dataSheetRow = row;

        // Also update any props that were carried into a detail view
        for (let prop in screenProps) {
          if (row[prop] !== undefined) {
            screenProps[prop] = row[prop];
          }
        }
        this.setState({currentScreenProps: screenProps});
      } else {
        this.setState({});
      }
    }
  }

  removeFromDataSheet = (sheetId, row) => {
    let sheet = this.dataSheets[sheetId];
    if (sheet && row) {
      sheet.removeItem(row, this['serviceOptions_'+sheetId] || {});
    }
    this.setState({});
  }

  updateDataSlot = (slotId, value, actionId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    this.dataSlots[slotId] = value;
    if (slotId === 'ds_activeLang') {
      this.locStrings.setLanguage(value);
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['matieres'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/flux", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_matieres.servicePath = servicePath;
        this.loadData_pointapp(this.dataSheets['matieres'], this.serviceOptions_matieres, true);
      }
    }
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['exutoires'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/exutoires", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_exutoires.servicePath = servicePath;
        this.loadData_pointapp(this.dataSheets['exutoires'], this.serviceOptions_exutoires, true);
      }
    }
    if (this.serviceOptions_selected_column.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['selected_column'].expandSlotTemplateString(this.serviceOptions_selected_column.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp(this.dataSheets['selected_column'], this.serviceOptions_selected_column, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['selected_column'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/columns", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_selected_column.servicePath = servicePath;
        this.loadData_pointapp(this.dataSheets['selected_column'], this.serviceOptions_selected_column, true);
      }
    }
    this.setState({});
  }

  dataSheetDidUpdate = (dataSheet) => {
    // This method is the default implementation and could be customized by a state management plugin.
    this.setState({});
  }

  updateLocalizationFromDataSheet = (dataSheet) => {
    const stringsObj = dataSheet.getStringsByLanguage();
    if (stringsObj && Object.keys(stringsObj).length > 0) {
      this.locStrings = new LocalizedStrings(stringsObj);
    } else {
      this.locStrings = new LocalizedStrings({en: {}});
    }
    this.locStrings.setLanguage(this.dataSlots['ds_activeLang']);
  }

  loadData_pointapp = (dataSheet, options, firstLoad) => {
    // This method was written by data plugin 'Firebase (Cloud Firestore)'.
    this.setState({loading: true});
    
    // clear any placeholder data before load
    if (firstLoad) {
      dataSheet.items = [];
    }
    
    const fetchComplete = (err) => {
      if (err) {
        // This error handling comes from React Studio
        // and currently doesn't do anything useful.
        console.error('** Web service load failed: ', err);
      } else {
      }
      this.setState({loading: false});
    }
    
    const db = firebase.firestore();
    let isCollectionGroup = options.servicePath.startsWith("group:");
    const collection = (isCollectionGroup) ? db.collectionGroup(options.servicePath.substring(6)) : db.collection(options.servicePath);
    const query = dataSheet.expandSlotTemplateString(options.query, this.dataSlots);
    let queryObj;
    
    if (query.length < 1) {
      queryObj = collection;
    } else {
      console.log("loading firebase data for '%s' with query: %s", options.servicePath, query);
      try {
        queryObj = eval(`(function(c){ return c.${query}; })(collection)`);
      } catch (e) {
        console.log("** error creating firebase query object from '%s': ", query, e)
        return;
      }
    }
    
    
    
    let refresh_data = (datasheet, data, options) => {
      dataSheet.loadFromJson(data);
      fetchComplete(null, options);
      
      if (datasheet.id == 'pav') {
        try {
          window.id_tournee_loader = setTimeout(() => { updateIdTournee(this) }, 1000);
        } catch (error) {
          console.log(error);
        }
      }
      if (datasheet.id == 'pav_j') {
        try {
          window.id_tournee_loader = setTimeout(() => { updateIdTourneeJ(this) }, 1000);
        } catch (error) {
          console.log(error);
        }
      }
    };
    
    queryObj.onSnapshot(
      (querySnapshot) => {
        let jsonArr = [];
        
        if (querySnapshot.docs) {
          querySnapshot.forEach((doc) => {
            const data = { ...doc.data(), document_key: doc.id, document_ref: doc.ref };
            // const data = { ...doc.data(), document_key: doc.id };
            jsonArr.push(data);
          });
        } else if (querySnapshot.data) {
          const doc = querySnapshot;
          const data = { ...doc.data(), document_key: doc.id, document_ref: doc.ref };
          // const data = { ...doc.data(), document_key: doc.id };
          jsonArr.push(data);
        }    
    
        refresh_data(dataSheet, jsonArr, options);    
        console.log(dataSheet.id);
    
      },
      (err) => {
        fetchComplete(err, options);
      });  
    
    
    let updateIdTournee = (appActions) => {
      let id_tournee = appActions.dataSheets['id_tournee'];
      let tournees = appActions.dataSheets['pav'].items.filter( it => it.date == appActions.dataSlots['ds_date_planning']);
      let max_id = 5;
      let new_items = id_tournee.items;
      id_tournee.items = [];
      for (let i=1; i<=max_id; i++) {
        let item = new_items[i-1] || {id: i, size: 0, distance: 0, time: 0, start_h: 0, start_m: 0 };
        let size = tournees.filter(item => (item.tournee == i)).length;
        item.size = size;
        id_tournee.addItem(item, '');
      }
      appActions.updateDataSlot('ds_nb_tournees', max_id);
    }
    
    let updateIdTourneeJ = (appActions) => {
      let id_tournee = appActions.dataSheets['id_tournee_j'];
      let tournees = appActions.dataSheets['pav_j'].items;
      let max_id = 5;
      let new_items = id_tournee.items;
      id_tournee.items = [];
      for (let i=1; i<=max_id; i++) {
        let item = new_items[i-1] || {id: i, size: 0, distance: 0, time: 0, start_h: 0, start_m: 0 };
        let size = tournees.filter(item => (item.tournee == i)).length;
        item.size = size;
        id_tournee.addItem(item, '');
      }
      appActions.updateDataSlot('ds_nb_tournees', max_id);
    }
     /*
    dbLoadingPromise.get().then((querySnapshot) => {
        let jsonArr = [];
    
        querySnapshot.forEach((doc) => {
          const data = { ...doc.data(), key: doc.id };
          jsonArr.push(data);
        });
            
        dataSheet.loadFromJson(jsonArr);
        fetchComplete(null, options);
      },
      (err) => {
        fetchComplete(err, options);
      });  
      */
    
  }

  render() {
    let makeElementForScreen = (screenId, baseProps, atTop, forward) => {
      let screenProps = {
        ...baseProps,
        atTopOfScreenStack: atTop,
        transitionForward: forward,
        appActions: this,
        dataSheets: this.dataSheets,
        locStrings: this.locStrings,
        deviceInfo: {
          screenFormatId: this.state.screenFormatId
        },
        'ds_activeLang': this.dataSlots['ds_activeLang'],
        'ds_site_id': this.dataSlots['ds_site_id'],
        'ds_entreprise_id': this.dataSlots['ds_entreprise_id'],
        'ds_username': this.dataSlots['ds_username'],
        'ds_email': this.dataSlots['ds_email'],
        'ds_image': this.dataSlots['ds_image'],
        'ds_userid': this.dataSlots['ds_userid'],
        'ds_entreprise': this.dataSlots['ds_entreprise'],
        'ds_right_forecast': this.dataSlots['ds_right_forecast'],
        'ds_admin': this.dataSlots['ds_admin'],
        'ds_login_success_screen': this.dataSlots['ds_login_success_screen'],
        'ds_login_screen_state': this.dataSlots['ds_login_screen_state'],
        'ds_demain_minuit_ts': this.dataSlots['ds_demain_minuit_ts'],
        'ds_selected_tournee': this.dataSlots['ds_selected_tournee'],
        'ds_today_minuit_ts': this.dataSlots['ds_today_minuit_ts'],
        'ds_camera': this.dataSlots['ds_camera'],
        'ds_saved_picture': this.dataSlots['ds_saved_picture'],
        'ds_saved_picture_document_key': this.dataSlots['ds_saved_picture_document_key'],
        'ds_state_camera': this.dataSlots['ds_state_camera'],
        'ds_site_enlevement': this.dataSlots['ds_site_enlevement'],
        'ds_matiere_enlevement': this.dataSlots['ds_matiere_enlevement'],
        'ds_exutoire_enlevement': this.dataSlots['ds_exutoire_enlevement'],
        'ds_tonnage_enlevement': this.dataSlots['ds_tonnage_enlevement'],
        'ds_commentaire': this.dataSlots['ds_commentaire'],
        'ds_selected_ot': this.dataSlots['ds_selected_ot'],
        'ds_volume_enlevement': this.dataSlots['ds_volume_enlevement'],
        'ds_anomalie_debordement': this.dataSlots['ds_anomalie_debordement'],
        'ds_anomalie_acces': this.dataSlots['ds_anomalie_acces'],
        'ds_anomalie_deteriore': this.dataSlots['ds_anomalie_deteriore'],
        'ds_anomalies': this.dataSlots['ds_anomalies'],
        'ds_state': this.dataSlots['ds_state'],
        'ds_state_autres': this.dataSlots['ds_state_autres'],
        'ds_mode_collecte': this.dataSlots['ds_mode_collecte'],
        'ds_address': this.dataSlots['ds_address'],
        'ds_scanner': this.dataSlots['ds_scanner'],
        'ds_scanned_id': this.dataSlots['ds_scanned_id'],
        'ds_vidage': this.dataSlots['ds_vidage'],
        'ds_from_ots': this.dataSlots['ds_from_ots'],
      };
      // A data sheet row was specified as the data source for this screen, so carry those props + 'dataSheetRow'.
      const dataSheetRow_ConteneurScreen = this.dataSheets['selected_column'].items[0];
      const screenData_ConteneurScreen = {
        ...dataSheetRow_ConteneurScreen,
        dataSheetRow: dataSheetRow_ConteneurScreen,
      }
      switch (screenId) {
        default:
          return null;
        case 'neurowaste':
          return (<NeurowasteScreen {...screenProps} />)
        case 'conteneur':
          return (<ConteneurScreen {...screenProps} {...screenData_ConteneurScreen} />)
        case 'appareilphoto':
          return (<AppareilPhotoScreen {...screenProps} />)
      }
    }

    let screenEl = makeElementForScreen(this.state.currentScreen, this.state.currentScreenProps, true, this.state.screenTransitionForward);
    let prevScreenEl = null;
    if (this.screenHistory.length >= 2) {  // For transitions, we want to show the previous screen below
      let prevScreenState = this.screenHistory[this.screenHistory.length - 2];
      prevScreenEl = makeElementForScreen(prevScreenState.currentScreen, prevScreenState.currentScreenProps, false, this.state.screenTransitionForward);
    }

    return (
      <div className="App">
        {prevScreenEl}
        {screenEl}
      </div>
    );
  }
}

import React, { Component } from 'react';
import './App.css';

// UI framework component imports
import Input from 'muicss/lib/react/input';

export default class Tonnage_volume extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      fieldCopy3: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_tonnage_enlevement'] : '') || '',
      fieldCopy2: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_volume_enlevement'] : '') || '',
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  textInputChanged_fieldCopy3 = (event) => {
    this.setState({fieldCopy3: event.target.value});
    
    let newVal = event.target.value;
    
    let transformValue = (input) => {
      return parseFloat(input);
    }
    newVal = transformValue(newVal);
    
    this.props.appActions.updateDataSlot("ds_tonnage_enlevement", newVal);
  }
  
  textInputChanged_fieldCopy2 = (event) => {
    this.setState({fieldCopy2: event.target.value});
    
    let newVal = event.target.value;
    
    let transformValue = (input) => {
      return parseFloat(input);  }
    newVal = transformValue(newVal);
    
    this.props.appActions.updateDataSlot("ds_volume_enlevement", newVal);
  }
  
  render() {
    const value_fieldCopy3 = this.state.fieldCopy3;
    
    const style_elFieldCopy3 = {
      display: 'block',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      pointerEvents: 'auto',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_fieldCopy2 = this.state.fieldCopy2;
    
    const style_elFieldCopy2 = {
      display: 'block',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      pointerEvents: 'auto',
     };
    const style_elTextCopy = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    return (
      <div className="Tonnage_volume">
        <div className="layoutFlow">
          <div className="elFieldCopy3">
            <Input className="baseFont" style={style_elFieldCopy3} type="number" hint={this.props.locStrings.comp1_fieldcopy3_1008541} onChange={this.textInputChanged_fieldCopy3} value={value_fieldCopy3 !== undefined ? value_fieldCopy3 : ''}  />
          </div>
          
          <div className="elText">
            <div className="baseFont" style={style_elText}>
              <div>{this.props.locStrings.tonnage_volume_text_399893}</div>
            </div>
          </div>
          
          <div className="elFieldCopy2">
            <Input className="baseFont" style={style_elFieldCopy2} type="number" hint={this.props.locStrings.comp1_fieldcopy2_127236} onChange={this.textInputChanged_fieldCopy2} value={value_fieldCopy2 !== undefined ? value_fieldCopy2 : ''}  />
          </div>
          
          <div className="elTextCopy">
            <div className="baseFont" style={style_elTextCopy}>
              <div>{this.props.locStrings.tonnage_volume_textcopy_826874}</div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}

import React, { Component } from 'react';
import './App.css';
import Tag_anomalie from './Tag_anomalie';

// UI framework component imports
import Checkbox from 'muicss/lib/react/checkbox';

export default class Item_anomalie extends Component {

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
      checkboxCopy: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_anomalie_debordement'] : ''),
      checkboxCopy: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_anomalie_debordement'] : ''),
      checkboxCopy: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_anomalie_debordement'] : ''),
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  // --- Functions for component state index 0 (1 of 3) --- 
  
  checkboxChanged_checkboxCopy = (event) => {
    this.setState({checkboxCopy: (event.target.checked ? 'true' : 'false')});
    this.props.appActions.updateDataSlot("ds_anomalie_debordement", (event.target.checked ? 'true' : 'false'));
  }
  
  renderState0() {
    let checked_checkboxCopy = this.state.checkboxCopy;
    
    const style_state0_elCheckboxCopy = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Item_anomalie">
        <div className="foreground">
          <Checkbox className="baseFont state0_elCheckboxCopy" style={style_state0_elCheckboxCopy}  label={this.props.locStrings.comp1_checkboxcopy_214398} onChange={this.checkboxChanged_checkboxCopy} checked={checked_checkboxCopy === 'true' || checked_checkboxCopy === true || ''+checked_checkboxCopy === '1'}  />
          <div className="hasNestedComps state0_elTag_anomalieCopy2">
            <Tag_anomalie ref={(el)=> this._state0_elTag_anomalieCopy2 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 3) --- 
  
  checkboxChanged_checkboxCopy = (event) => {
    this.setState({checkboxCopy: (event.target.checked ? 'true' : 'false')});
    this.props.appActions.updateDataSlot("ds_anomalie_debordement", (event.target.checked ? 'true' : 'false'));
  }
  
  renderState1() {
    let checked_checkboxCopy = this.state.checkboxCopy;
    
    const style_state1_elCheckboxCopy = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Item_anomalie">
        <div className="foreground">
          <Checkbox className="baseFont state1_elCheckboxCopy" style={style_state1_elCheckboxCopy}  label={this.props.locStrings.item_anomalie_checkboxcopy_751858} onChange={this.checkboxChanged_checkboxCopy} checked={checked_checkboxCopy === 'true' || checked_checkboxCopy === true || ''+checked_checkboxCopy === '1'}  />
          <div className="hasNestedComps state1_elTag_anomalieCopy2">
            <Tag_anomalie visualStateIndex={1} ref={(el)=> this._state1_elTag_anomalieCopy2 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 3) --- 
  
  checkboxChanged_checkboxCopy = (event) => {
    this.setState({checkboxCopy: (event.target.checked ? 'true' : 'false')});
    this.props.appActions.updateDataSlot("ds_anomalie_debordement", (event.target.checked ? 'true' : 'false'));
  }
  
  renderState2() {
    let checked_checkboxCopy = this.state.checkboxCopy;
    
    const style_state2_elCheckboxCopy = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Item_anomalie">
        <div className="foreground">
          <Checkbox className="baseFont state2_elCheckboxCopy" style={style_state2_elCheckboxCopy}  label={this.props.locStrings.item_anomalie_checkboxcopy_70090} onChange={this.checkboxChanged_checkboxCopy} checked={checked_checkboxCopy === 'true' || checked_checkboxCopy === true || ''+checked_checkboxCopy === '1'}  />
          <div className="hasNestedComps state2_elTag_anomalieCopy2">
            <Tag_anomalie visualStateIndex={2} ref={(el)=> this._state2_elTag_anomalieCopy2 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
    }
  }
  
}

import React, { Component } from 'react';
import './App.css';
import img_state0_elImage from './images/Image_pav_state0_elImage_991161.png';
import img_state1_elImage from './images/Image_pav_state1_elImage_882074.png';
import img_state2_elImage from './images/Image_pav_state2_elImage_218002.png';

export default class Image_pav extends Component {

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  // --- Functions for component state index 0 (1 of 3) --- 
  
  renderState0() {
    const style_state0_elImage = {
      backgroundImage: 'url('+img_state0_elImage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    return (
      <div className="Image_pav">
        <div className="foreground">
          <div className="containerMinHeight state0_elImage" style={style_state0_elImage} />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 3) --- 
  
  renderState1() {
    const style_state1_elImage = {
      backgroundImage: 'url('+img_state1_elImage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    return (
      <div className="Image_pav">
        <div className="foreground">
          <div className="containerMinHeight state1_elImage" style={style_state1_elImage} />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 3) --- 
  
  renderState2() {
    const style_state2_elImage = {
      backgroundImage: 'url('+img_state2_elImage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    return (
      <div className="Image_pav">
        <div className="foreground">
          <div className="containerMinHeight state2_elImage" style={style_state2_elImage} />
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
    }
  }
  
}

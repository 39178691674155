import React, { Component } from 'react';
import './App.css';

export default class Level extends Component {

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  // --- Functions for component state index 0 (1 of 5) --- 
  
  renderState0() {
    
    const style_state0_elRectangleCopy3 = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    
    const style_state0_elRectangleCopy216603 = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    
    const style_state0_elRectangleCopy760407 = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    
    const style_state0_elRectangle720604 = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    
    return (
      <div className="Level">
        <div className="foreground">
          <div className="state0_elRectangleCopy3" style={style_state0_elRectangleCopy3} />
          <div className="state0_elRectangleCopy216603" style={style_state0_elRectangleCopy216603} />
          <div className="state0_elRectangleCopy760407" style={style_state0_elRectangleCopy760407} />
          <div className="state0_elRectangle720604" style={style_state0_elRectangle720604} />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 5) --- 
  
  renderState1() {
    
    const style_state1_elRectangleCopy3684617 = {
      background: 'rgba(114, 250, 120, 1.000)',
     };
    
    const style_state1_elRectangleCopy216603 = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    
    const style_state1_elRectangleCopy760407 = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    
    const style_state1_elRectangle720604 = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    
    return (
      <div className="Level">
        <div className="foreground">
          <div className="state1_elRectangleCopy3684617" style={style_state1_elRectangleCopy3684617} />
          <div className="state1_elRectangleCopy216603" style={style_state1_elRectangleCopy216603} />
          <div className="state1_elRectangleCopy760407" style={style_state1_elRectangleCopy760407} />
          <div className="state1_elRectangle720604" style={style_state1_elRectangle720604} />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 5) --- 
  
  renderState2() {
    
    const style_state2_elRectangleCopy3684617 = {
      background: 'rgba(114, 250, 120, 1.000)',
     };
    
    const style_state2_elRectangleCopy2850173 = {
      background: 'rgba(114, 250, 120, 1.000)',
     };
    
    const style_state2_elRectangleCopy760407 = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    
    const style_state2_elRectangle720604 = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    
    return (
      <div className="Level">
        <div className="foreground">
          <div className="state2_elRectangleCopy3684617" style={style_state2_elRectangleCopy3684617} />
          <div className="state2_elRectangleCopy2850173" style={style_state2_elRectangleCopy2850173} />
          <div className="state2_elRectangleCopy760407" style={style_state2_elRectangleCopy760407} />
          <div className="state2_elRectangle720604" style={style_state2_elRectangle720604} />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 3 (4 of 5) --- 
  
  renderState3() {
    
    const style_state3_elRectangleCopy3684617 = {
      background: 'rgba(114, 250, 120, 1.000)',
     };
    
    const style_state3_elRectangleCopy2850173 = {
      background: 'rgba(114, 250, 120, 1.000)',
     };
    
    const style_state3_elRectangleCopy417639 = {
      background: 'rgba(255, 212, 120, 1.000)',
     };
    
    const style_state3_elRectangle720604 = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    
    return (
      <div className="Level">
        <div className="foreground">
          <div className="state3_elRectangleCopy3684617" style={style_state3_elRectangleCopy3684617} />
          <div className="state3_elRectangleCopy2850173" style={style_state3_elRectangleCopy2850173} />
          <div className="state3_elRectangleCopy417639" style={style_state3_elRectangleCopy417639} />
          <div className="state3_elRectangle720604" style={style_state3_elRectangle720604} />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 4 (5 of 5) --- 
  
  renderState4() {
    
    const style_state4_elRectangleCopy3684617 = {
      background: 'rgba(114, 250, 120, 1.000)',
     };
    
    const style_state4_elRectangleCopy2850173 = {
      background: 'rgba(114, 250, 120, 1.000)',
     };
    
    const style_state4_elRectangleCopy417639 = {
      background: 'rgba(255, 212, 120, 1.000)',
     };
    
    const style_state4_elRectangle = {
      background: 'rgba(255, 125, 120, 1.000)',
     };
    
    return (
      <div className="Level">
        <div className="foreground">
          <div className="state4_elRectangleCopy3684617" style={style_state4_elRectangleCopy3684617} />
          <div className="state4_elRectangleCopy2850173" style={style_state4_elRectangleCopy2850173} />
          <div className="state4_elRectangleCopy417639" style={style_state4_elRectangleCopy417639} />
          <div className="state4_elRectangle" style={style_state4_elRectangle} />
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
      case 3:
        return this.renderState3();
      case 4:
        return this.renderState4();
    }
  }
  
}

import React, { Component } from 'react';
import './App.css';

export default class Tag_anomalie extends Component {

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  // --- Functions for component state index 0 (1 of 3) --- 
  
  renderState0() {
    
    const style_state0_elRectangle = {
      background: 'rgba(255, 212, 120, 1.000)',
      borderRadius: '11.0px',
     };
    const style_state0_elText = {
      fontSize: 15.1,
      color: '#feffff',
      textAlign: 'center',
     };
    
    return (
      <div className="Tag_anomalie">
        <div className="foreground">
          <div className="containerMinHeight state0_elRectangle" style={style_state0_elRectangle} />
          <div className="systemFontRegular  state0_elText" style={style_state0_elText}>
            <div>{this.props.locStrings.tag_anomalie_text_372640}</div>
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 3) --- 
  
  renderState1() {
    
    const style_state1_elRectangle = {
      background: 'rgba(0, 145, 146, 1.000)',
      borderRadius: '11.0px',
     };
    const style_state1_elText = {
      fontSize: 15.1,
      color: '#feffff',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_state1_elText_inner = {
      textAlign: 'center',
     };
    
    return (
      <div className="Tag_anomalie">
        <div className="foreground">
          <div className="containerMinHeight state1_elRectangle" style={style_state1_elRectangle} />
          <div className="systemFontRegular  containerMinHeight state1_elText" style={style_state1_elText}>
            <div className="bottomAlignedContent">{this.props.locStrings.tag_anomalie_text_75922}</div>
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 3) --- 
  
  renderState2() {
    
    const style_state2_elRectangle = {
      background: 'rgba(255, 125, 120, 1.000)',
      borderRadius: '11.0px',
     };
    const style_state2_elText = {
      fontSize: 15.1,
      color: '#feffff',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_state2_elText_inner = {
      textAlign: 'center',
     };
    
    return (
      <div className="Tag_anomalie">
        <div className="foreground">
          <div className="containerMinHeight state2_elRectangle" style={style_state2_elRectangle} />
          <div className="systemFontRegular  containerMinHeight state2_elText" style={style_state2_elText}>
            <div className="bottomAlignedContent">{this.props.locStrings.tag_anomalie_text_464738}</div>
          </div>
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
    }
  }
  
}

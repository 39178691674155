import React, { Component } from 'react';
import './App.css';
import Camera from './Camera';
import btn_icon_642611 from './images/btn_icon_642611.png';
import img_elImage from './images/NeurowasteScreen_elImage_158738.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class NeurowasteScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
      elCapture_visible: true,
      elWait_visible: false,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  onClick_elCapture = (ev) => {
    let scan_one = (depth) => {
      if (depth < 5) {
        console.log(depth);
        this._elScanner.savePicture()
        let data = {image: this.props.appActions.dataSlots['ds_camera']};
        let request = new XMLHttpRequest();
        request.open('POST', 'https://europe-west1-neurowastepoint.cloudfunctions.net/getDumpsterId');
        request.setRequestHeader('Content-type', 'application/json');
        request.onload = () => {
          let column_id = request.response;
          console.log(column_id);
          if (column_id !== "") {
            this.props.appActions.updateDataSlot('ds_scanned_id', column_id);
            this.props.appActions.updateDataSlot('ds_from_ots', false);
            this.props.appActions.updateDataSlot('ds_vidage', "false");
            this.props.appActions.updateDataSlot('ds_camera', "");
            //this.setState({elCapture_visible: true});
            //this.setState({elWait_visible: false});
            // this._elScanner.stopFlow();
            this.props.appActions.goToScreen("conteneur", { transitionId: 'fadeIn' });
            return;
          } else { 
            scan_one(depth + 1);
          }
        };
        request.send(JSON.stringify(data)) // Make sure to stringify
      } else {
        window.alert('Identifiant du contenant illisible');
        this.setState({elCapture_visible: true});
        this.setState({elWait_visible: false});
        return;
      }
    }
    
    scan_one(0);
    
  
    this.setState({elCapture_visible: false});
  
    this.setState({elWait_visible: true});
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    const style_elScanner = {
      pointerEvents: 'auto',
     };
    const style_elCapture = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elCapture = this.state.elCapture_visible ? (
      <Button className="actionFont elCapture" style={style_elCapture}  color="accent" onClick={this.onClick_elCapture} >
        {this.props.locStrings.scanner_capturecopy_328427}
      </Button>
     ) : null;
    const style_elWait = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_642611+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '86.000%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    const elWait = this.state.elWait_visible ? (
      <button className="actionFont elWait" style={style_elWait} />
     ) : null;
    const style_elImage = {
      backgroundImage: 'url('+img_elImage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    return (
      <div className="AppScreen NeurowasteScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        <Appbar className="navBar">
        </Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elScanner" style={style_elScanner}>
              <Camera ref={(el)=> this._elScanner = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            { elCapture }
            { elWait }
            <div className="elImage" style={style_elImage} />
          </div>
        </div>
      </div>
    )
  }
  
}

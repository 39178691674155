import DataSheetBase from './DataSheetBase.js';
import datasheet_tri_row0_image from './images/datasheet_tri_row0_image.png';
import datasheet_tri_row1_image from './images/datasheet_tri_row1_image.png';
import datasheet_tri_row2_image from './images/datasheet_tri_row2_image.png';
import datasheet_tri_row3_image from './images/datasheet_tri_row3_image.png';
import datasheet_tri_row4_image from './images/datasheet_tri_row4_image.png';

export default class DataSheet_tri extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['flux'] = "EMBALLAGES";
    item['image'] = datasheet_tri_row0_image;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['flux'] = "EMBALLAGES";
    item['image'] = datasheet_tri_row1_image;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['flux'] = "EMBALLAGES";
    item['image'] = datasheet_tri_row2_image;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['flux'] = "OM";
    item['image'] = datasheet_tri_row3_image;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['flux'] = "VERRE";
    item['image'] = datasheet_tri_row4_image;
    item.key = key++;
  }

}

import React, { Component } from 'react';
import './App.css';
import Camera from './Camera';
import btn_icon_328427 from './images/btn_icon_328427.png';
import btn_icon_back_appareilphoto from './images/btn_icon_back_appareilphoto.png';

// UI framework component imports
import Appbar from 'muicss/lib/react/appbar';

export default class AppareilPhotoScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  onClick_elCapture_camera = (ev) => {
    // Perform action 'Shoot' on element 'camera'
    this._elCamera.savePicture()
    this.props.appActions.dataSlots['ds_saved_picture'] = this.props.appActions.dataSlots['ds_camera'];
    // this._elCamera.stopFlow();
  
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    const style_elCamera = {
      pointerEvents: 'auto',
     };
    const style_elCapture_camera = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_328427+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '42.000%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen AppareilPhotoScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Appareil photo</div>  <div className="backBtn" onClick={ (ev)=>{ this.props.appActions.goBack() } }><img src={btn_icon_back_appareilphoto} alt="" style={{width: '50%'}} /></div>
        </Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elCamera" style={style_elCamera}>
              <Camera ref={(el)=> this._elCamera = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <button className="actionFont elCapture_camera" style={style_elCapture_camera} onClick={this.onClick_elCapture_camera}  />
          </div>
        </div>
      </div>
    )
  }
  
}

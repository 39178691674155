import React, { Component } from 'react';
import './App.css';
import img_elImage from './images/Item_tri_elImage_706248.jpg';
import Cross from './Cross';

export default class Item_tri extends Component {

  // Properties used by this component:
  // flux, image

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    const style_elImage = {
      backgroundImage: 'url('+(this.props.image || img_elImage)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    let transformVisiblePropValue_cross = (input) => {
      return input === (this.props.appActions.dataSheets['selected_column'].items[0] || {}).flux ? "false" : "true";
    }
    const elCross = (transformVisiblePropValue_cross(this.props.flux) === "true") ? (
      <div className="hasNestedComps containerMinHeight elCross">
        <Cross ref={(el)=> this._elCross = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="Item_tri cardBg">
        <div className="foreground">
          <div className="containerMinHeight elImage" style={style_elImage} />
          { elCross }
        </div>
      </div>
    )
  }
  
}
